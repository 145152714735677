import nx from "./nx.png";
import ny from "./ny.png";
import nz from "./nz.png";
import px from "./px.png";
import py from "./py.png";
import pz from "./pz.png";

const pisaRGBM16 = [px, nx, py, ny, pz, nz];

export default pisaRGBM16;
